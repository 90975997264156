<template>
  <div id="app">
    <transition name="fade">
      <div class="loading-screen" v-if="isLoading">
        <img src="./assets/wd_logo.png" alt="" width="300">
      </div>
    </transition>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" display="none" width="0" height="0">
      <!-- <symbol id="icon-901" viewBox="0 0 40 40">
        <path d="M34.9,30.5V15.6c-0.4,0.4-0.8,0.9-1.4,1.2c-3.4,2.7-6.2,4.8-8.2,6.6c-0.6,0.5-1.1,0.9-1.6,1.2c-0.4,0.3-0.9,0.6-1.7,0.9 c-0.7,0.3-1.4,0.5-2,0.5l0,0c-0.6,0-1.2-0.2-2-0.5c-0.7-0.3-1.2-0.6-1.7-0.9c-0.4-0.3-0.9-0.7-1.6-1.2c-2.1-1.7-4.8-3.8-8.2-6.6 c-0.5-0.4-0.9-0.8-1.4-1.2v14.9c0,0.2,0.1,0.3,0.2,0.4C5.7,31,5.9,31.1,6,31.1h28.4c0.2,0,0.3-0.1,0.4-0.2 C34.8,30.8,34.9,30.7,34.9,30.5L34.9,30.5z M34.9,10.2V9.7c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.1-0.2 c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.2,0-0.3,0H5.8C5.6,8.9,5.4,9,5.3,9.1C5.2,9.2,5.1,9.3,5.1,9.5c0,2.2,0.9,4,2.8,5.5 c2.5,2,5.1,4,7.7,6.1c0.1,0.1,0.3,0.2,0.7,0.5c0.4,0.3,0.6,0.5,0.9,0.7c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.2,0.7,0.4,0.9,0.5 c0.3,0.1,0.6,0.2,0.8,0.2l0,0c0.2,0,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.4,0.9-0.7 c0.4-0.3,0.6-0.5,0.6-0.5c2.7-2.1,5.3-4.2,7.7-6.1c0.7-0.5,1.4-1.2,2-2.2C34.6,11.8,34.9,11,34.9,10.2L34.9,10.2z M37.3,9.5v21 c0,0.8-0.3,1.6-0.9,2.2s-1.4,0.9-2.2,0.9H5.8c-0.8,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2v-21c0-0.8,0.3-1.6,0.9-2.2 s1.4-0.9,2.2-0.9h28.4c0.8,0,1.6,0.3,2.2,0.9S37.3,8.7,37.3,9.5z"></path>
      </symbol> -->
      <symbol id="icon-910" viewBox="0 0 40 40">
        <path d="M20,7c4.2,0,4.7,0,6.3,0.1c1.5,0.1,2.3,0.3,3,0.5C30,8,30.5,8.3,31.1,8.9c0.5,0.5,0.9,1.1,1.2,1.8c0.2,0.5,0.5,1.4,0.5,3 C33,15.3,33,15.8,33,20s0,4.7-0.1,6.3c-0.1,1.5-0.3,2.3-0.5,3c-0.3,0.7-0.6,1.2-1.2,1.8c-0.5,0.5-1.1,0.9-1.8,1.2 c-0.5,0.2-1.4,0.5-3,0.5C24.7,33,24.2,33,20,33s-4.7,0-6.3-0.1c-1.5-0.1-2.3-0.3-3-0.5C10,32,9.5,31.7,8.9,31.1 C8.4,30.6,8,30,7.7,29.3c-0.2-0.5-0.5-1.4-0.5-3C7,24.7,7,24.2,7,20s0-4.7,0.1-6.3c0.1-1.5,0.3-2.3,0.5-3C8,10,8.3,9.5,8.9,8.9 C9.4,8.4,10,8,10.7,7.7c0.5-0.2,1.4-0.5,3-0.5C15.3,7.1,15.8,7,20,7z M20,4.3c-4.3,0-4.8,0-6.5,0.1c-1.6,0-2.8,0.3-3.8,0.7 C8.7,5.5,7.8,6,6.9,6.9C6,7.8,5.5,8.7,5.1,9.7c-0.4,1-0.6,2.1-0.7,3.8c-0.1,1.7-0.1,2.2-0.1,6.5s0,4.8,0.1,6.5 c0,1.6,0.3,2.8,0.7,3.8c0.4,1,0.9,1.9,1.8,2.8c0.9,0.9,1.7,1.4,2.8,1.8c1,0.4,2.1,0.6,3.8,0.7c1.6,0.1,2.2,0.1,6.5,0.1 s4.8,0,6.5-0.1c1.6-0.1,2.9-0.3,3.8-0.7c1-0.4,1.9-0.9,2.8-1.8c0.9-0.9,1.4-1.7,1.8-2.8c0.4-1,0.6-2.1,0.7-3.8 c0.1-1.6,0.1-2.2,0.1-6.5s0-4.8-0.1-6.5c-0.1-1.6-0.3-2.9-0.7-3.8c-0.4-1-0.9-1.9-1.8-2.8c-0.9-0.9-1.7-1.4-2.8-1.8 c-1-0.4-2.1-0.6-3.8-0.7C24.8,4.3,24.3,4.3,20,4.3L20,4.3L20,4.3z"></path>
        <path d="M20,11.9c-4.5,0-8.1,3.7-8.1,8.1s3.7,8.1,8.1,8.1s8.1-3.7,8.1-8.1S24.5,11.9,20,11.9z M20,25.2c-2.9,0-5.2-2.3-5.2-5.2 s2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2S22.9,25.2,20,25.2z"></path>
        <path d="M30.6,11.6c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9C29.8,9.7,30.6,10.5,30.6,11.6z"></path>
      </symbol>
      <symbol id="icon-905" viewBox="0 0 40 40">
        <path d="M36.3,10.2c-1,1.3-2.1,2.5-3.4,3.5c0,0.2,0,0.4,0,1c0,1.7-0.2,3.6-0.9,5.3c-0.6,1.7-1.2,3.5-2.4,5.1 c-1.1,1.5-2.3,3.1-3.7,4.3c-1.4,1.2-3.3,2.3-5.3,3c-2.1,0.8-4.2,1.2-6.6,1.2c-3.6,0-7-1-10.2-3c0.4,0,1.1,0.1,1.5,0.1 c3.1,0,5.9-1,8.2-2.9c-1.4,0-2.7-0.4-3.8-1.3c-1.2-1-1.9-2-2.2-3.3c0.4,0.1,1,0.1,1.2,0.1c0.6,0,1.2-0.1,1.7-0.2 c-1.4-0.3-2.7-1.1-3.7-2.3s-1.4-2.6-1.4-4.2v-0.1c1,0.6,2,0.9,3,0.9c-1-0.6-1.5-1.3-2.2-2.4c-0.6-1-0.9-2.1-0.9-3.3s0.3-2.3,1-3.4 c1.5,2.1,3.6,3.6,6,4.9s4.9,2,7.6,2.1c-0.1-0.6-0.1-1.1-0.1-1.4c0-1.8,0.8-3.5,2-4.7c1.2-1.2,2.9-2,4.7-2c2,0,3.6,0.8,4.8,2.1 c1.4-0.3,2.9-0.9,4.2-1.5c-0.4,1.5-1.4,2.7-2.9,3.6C33.8,11.2,35.1,10.9,36.3,10.2L36.3,10.2z"></path>
      </symbol>
      <!-- <symbol id="icon-920" viewBox="0 0 40 40">
        <path d="M14,11.6v-9h12v9H14z M26,16.6h10v18H4v-18h10v9h12V16.6z"></path>
      </symbol> -->
    </svg>
    <div id="wrapper">
      <div id="main">
        <div class="inner">
          <div id="container01" class="container columns">
            <div class="wrapper">
              <div class="inner">
                <div>
                  <div id="image01" class="image">
                    <img src="@/assets/wd_logo.png" alt="first img">
                  </div>
                  <h1 id="text02">Warft Design</h1>
                  <h2 id="text03">{{ heroText }}</h2>
                  <p id="text01">{{ introText }}</p>
                  <ul id="icons01" class="icons">
                    <!-- <li>
                      <a class="n01" href="https://unsplash.com/">
                        <svg>
                          <use xlink:href="#icon-920"></use>
                        </svg>
                        <span class="label">Unsplash</span>
                      </a>
                    </li> -->
                    <li>
                      <a class="n02" :href="instaUrl" target="_blank">
                        <svg>
                          <use xlink:href="#icon-910"></use>
                        </svg>
                        <span class="label">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a class="n03" :href="twitterUrl" target="_blank">
                        <svg>
                          <use xlink:href="#icon-905"></use>
                        </svg>
                        <span class="label">Twitter</span>
                      </a>
                    </li>
                    <!-- <li>
                      <a class="n04" href="">
                        <svg>
                          <use xlink:href="#icon-901"></use>
                        </svg>
                        <span class="label">Email (alt)</span>
                      </a>
                    </li> -->
                  </ul>
                </div>
                <div>
                  <div id="gallery01" class="gallery">
                    <div class="inner">
                      <ul>
                        <li v-for="(image, index) in galleryItems" :key="index">
                          <a @click="openModal(index)" class="thumbnail">
                            <img :src="image.thumbnail" :alt="image.caption">
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p id="text04">
            <span>&copy; {{ new Date().getFullYear() }} Warft Design</span><br> 
            <span class="toggle-impressum" @click="impressumOpen = !impressumOpen">Impressum {{ impressumOpen ? "einklappen" : "" }}</span>
          </p>
          <p id="text05" v-show="impressumOpen" v-html="impressum"></p>
        </div>
      </div>
      <div
        id="gallery-modal"
        tabindex="-1"
        class="gallery-modal"
        :class="{ visible: modalOpen }">
        <div class="inner">
          <img :src="galleryItems[index].src">
        </div>
        <div class="caption">{{ galleryItems[index].caption }}</div>
        <div class="nav previous" @click="prevImg()"></div>
        <div class="nav next" @click="nextImg()"></div>
        <div class="close" @click="closeModal()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const apiRoot = process.env.VUE_APP_API_ROOT;
const homeApi = process.env.VUE_APP_API_HOME;
const galleryApi = process.env.VUE_APP_API_GALLERY;


export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      introText: null,
      heroText: null,
      instaUrl: null,
      twitterUrl: null,
      impressum: null,
      index: 0,
      galleryItems: [],
      modalOpen: false,
      impressumOpen: false
    }
  },
  components: {
  },
  methods: {
    openModal(index) {
      this.index = index;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    nextImg() {
      if (this.index >= this.galleryItems.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    },
    prevImg() {
      if (this.index === 0) {
        this.index = this.galleryItems.length - 1;
      } else {
        this.index--;
      }
    }
  },
  mounted() {
    axios
      .get(apiRoot + homeApi)
      .then((response) => {
        const res = response.data[0].acf;

        this.introText = res.intro_text;
        this.heroText = res.hero_text;
        this.instaUrl = res.instagram_url;
        this.twitterUrl = res.twitter_url;
        this.impressum = res.impressum;

        this.isLoading = false;

        return axios.get(apiRoot + galleryApi);
      })
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          const imgItem = res.data[i];
          let galleryItem = {};

          galleryItem["src"] = imgItem.acf.image.url;
          galleryItem["thumbnail"] = imgItem.acf.image.sizes.large;
          galleryItem["caption"] = imgItem.acf.caption.replace(/(\d)x(\d)/g, '$1×$2');

          this.galleryItems.push(galleryItem);
        }
      })
      .catch((err) => {
        console.error(err);
      })    
  }
}
</script>

<style>
@import "assets/style.css";

html {
  overflow-y: scroll;
}

#app {
  font-family: "Rubik", Arial, Helvetica, sans-serif;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: #fff;
}

.caption {
  position: absolute;
  bottom: 10rem;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
}

.toggle-impressum {
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
